<template>
  <v-card>
    <v-card-title>DHL Kleinpaket</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="2" class="mr-3 hidden-xs-only">
          <v-container fill-height>
            <v-icon color="secondary" class="alert-icon" :size="75">mdi-alert</v-icon>
          </v-container>
        </v-col>
        <v-col>
          <v-container>
            Höchstmaß: Länge 35,3 cm, Breite 25,0 cm, Höhe 5,0 cm<br>
            Höchstgewicht: 1.000 g<br>
            <strong>Nicht größer als Maxi-Brief-Karton!</strong>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DHLWarning"
}
</script>

<style scoped>

</style>